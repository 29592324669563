export enum ItemTypes {
    folder,
    pdf,
    video,
    image,
    txt,
    app,
    link,
    widget,
    safariWidget
  }